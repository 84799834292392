import bg1URL from './img/photos/178j8tJrNlc.jpg';
import bg2URL from './img/photos/9rYfG8sWRVo.jpg';
import bg3URL from './img/photos/LK3sqLiBw.jpg';
import bg4URL from './img/photos/MP0bgaS_d1c.jpg';
import svg1URL from './img/svgs/calling.svg';
import svg2URL from  './img/svgs/engineer.svg';
import svg3URL from './img/svgs/estimate.svg';
import svg4URL from './img/svgs/handshake.svg';
import svg5URL from './img/svgs/construction.svg';

gsap.registerPlugin(ScrollTrigger);

var tl = gsap.timeline( {repeat: -1});

    tl.fromTo(".hero-img", {autoAlpha:0}, {backgroundImage: function() {return 'url("' + bg1URL + '")';}, autoAlpha:1, duration:.1});
    tl.fromTo(".hero-img", {autoAlpha:1}, {autoAlpha:0, duration:.5}, "+=4");
    tl.fromTo(".hero-img", {autoAlpha:0}, {backgroundImage: function() {return 'url("' + bg2URL + '")';}, autoAlpha:1, duration:.1});
    tl.fromTo(".hero-img", {autoAlpha:1}, {autoAlpha:0, duration:.5}, "+=4");
    tl.fromTo(".hero-img", {autoAlpha:0}, {backgroundImage: function() {return 'url("' + bg3URL + '")';}, autoAlpha:1, duration:.1});
    tl.fromTo(".hero-img", {autoAlpha:1}, {autoAlpha:0, duration:.5}, "+=4");
    tl.fromTo(".hero-img", {autoAlpha:0}, {backgroundImage: function() {return 'url("' + bg4URL + '")';}, autoAlpha:1, duration:.1});
    tl.fromTo(".hero-img", {autoAlpha:1}, {autoAlpha:0, duration:.5}, "+=4");


var tl2 = gsap.timeline({repeat:-1, defaults: {duration:0}, scrollTrigger: {
    trigger: "#steps",
    start: "top bottom",
}});

    tl2.to(".steps-img", {backgroundImage: function() {return 'url("' + svg1URL + '")';}});
    tl2.to(".step1-text", {fontWeight: "bold"});
    tl2.to(".step1-icon", {backgroundColor: "#4a5568"});
    tl2.to(".step5-icon", {backgroundColor: "#718096"});
    tl2.to(".step1-text", {fontWeight: "normal"}, "+=5");

    tl2.to(".steps-img", {backgroundImage: function() {return 'url("' + svg2URL + '")';}});
    tl2.to(".step2-text", {fontWeight: "bold"});
    tl2.to(".step2-icon", {backgroundColor: "#4a5568"});
    tl2.to(".step1-icon", {backgroundColor: "#718096"});
    tl2.to(".step2-text", {fontWeight: "normal"}, "+=5");

    tl2.to(".steps-img", {backgroundImage: function() {return 'url("' + svg3URL + '")';}});
    tl2.to(".step3-text", {fontWeight: "bold"});
    tl2.to(".step3-icon", {backgroundColor: "#4a5568"});
    tl2.to(".step2-icon", {backgroundColor: "#718096"});
    tl2.to(".step3-text", {fontWeight: "normal"}, "+=5");

    tl2.to(".steps-img", {backgroundImage: function() {return 'url("' + svg4URL + '")';}});
    tl2.to(".step4-text", {fontWeight: "bold"});
    tl2.to(".step4-icon", {backgroundColor: "#4a5568"});
    tl2.to(".step3-icon", {backgroundColor: "#718096"});
    tl2.to(".step4-text", {fontWeight: "normal"}, "+=5");

    tl2.to(".steps-img", {backgroundImage: function() {return 'url("' + svg5URL + '")';}});
    tl2.to(".step5-text", {fontWeight: "bold"});
    tl2.to(".step5-icon", {backgroundColor: "#4a5568"});
    tl2.to(".step4-icon", {backgroundColor: "#718096"});
    tl2.to(".step5-text", {fontWeight: "normal"}, "+=5");


gsap.fromTo(".service-card", {autoAlpha:0, y:200}, {scrollTrigger: "#services", autoAlpha:1, y:0, delay:1, stagger:.25});
gsap.fromTo(".testimonials-card", {autoAlpha:0, rotationY:45}, {scrollTrigger: "#testimonials", autoAlpha:1, rotationY:0, delay:1, duration:1, stagger:.5});